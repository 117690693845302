import * as React from 'react';
import { Link } from 'gatsby';

import '../css/reset.css';
import '../css/general.css';

const NotFoundPage = () => {
  return (
    <main>
      <h1>Page not found</h1>
      <p>
        <br />
        <Link to="/">Go home</Link>.
      </p>
    </main>
  );
};

export default NotFoundPage;

export const Head = () => <title>Not found</title>;
